<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>项目管理</el-breadcrumb-item>
      <el-breadcrumb-item>项目成员管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片区 -->
    <el-card>
      <!-- 超级管理员 -->
      <div v-if="roleName =='超级管理员' ">
        <!-- 搜索成员 -->
        <el-row>
          <el-col :span="7">
            <el-input placeholder="请输入成员名称" @keyup.enter="getItemMemList()" clearable @clear="getItemMemList()">
              <template #append>
                <el-button icon="el-icon-search" @click="getItemMemList()"></el-button>
              </template>
            </el-input>
          </el-col>
          <el-col :span="17"></el-col>
        </el-row>
        <el-tabs type="border-card" style="margin-top:10px">
          <el-tab-pane>
            <template #label>本项目成员</template>
            <el-table :data="itemMemList" stripe border style="width: 100%">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column label="所属公司名称" prop="companyName"></el-table-column>
              <el-table-column label="成员岗位" prop="roleName"></el-table-column>
              <el-table-column label="手机号码" prop="phone"></el-table-column>
              <el-table-column label="操作" min-width="180px">
                <template v-slot="scope">
                  <el-button size="mini" type="danger" icon="el-icon-delete" @click="removeUser(scope.row)"
                    :disabled="this.getItemState(scope.row)">移出</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <el-config-provider :locale="locale">
          <el-pagination @size-change="ItemSizeChange" @current-change="ItemCurChange" :current-page="itemQuery.pagenum"
            :page-sizes="[5,10,20]" :page-size="itemQuery.pagesize" layout="total, sizes, prev, pager, next, jumper"
            :total="itemTotal"></el-pagination>
        </el-config-provider>
      </div>

      <!-- 项目负责人 -->
      <div v-if="roleName =='项目负责人' || '公司管理员'">
        <!-- 搜索成员 -->
        <el-row>
          <el-col :span="7">
            <el-input placeholder="请输入成员名称" @keyup.enter="getItemMemList()" clearable @clear="getItemMemList()">
              <template #append>
                <el-button icon="el-icon-search" @click="getItemMemList()"></el-button>
              </template>
            </el-input>
          </el-col>
        </el-row>
        <el-tabs type="border-card" style="margin-top:10px">
          <el-tab-pane>
            <template #label>本项目成员</template>
            <el-table :data="itemMemList" stripe border style="width: 100%">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column label="所在公司" prop="companyName" v-slot="scope">{{scope.row.companyName == null ? '尚为加入任何公司':scope.row.companyName}}</el-table-column>
              <el-table-column label="成员岗位" prop="roleName"></el-table-column>
              <el-table-column label="手机号码" prop="phone"></el-table-column>
              <el-table-column label="操作" min-width="180px">
                <template v-slot="scope">
                  <el-button size="mini" type="danger" icon="el-icon-delete" @click="removeUser(scope.row)"
                    :disabled="this.getItemState(scope.row)">移出</el-button>
                </template>

              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <el-config-provider :locale="locale">
          <el-pagination @size-change="ItemSizeChange" @current-change="ItemCurChange" :current-page="itemQuery.pagenum"
            :page-sizes="[5,10,20]" :page-size="itemQuery.pagesize" layout="total, sizes, prev, pager, next, jumper"
            :total="itemTotal"></el-pagination>
        </el-config-provider>
      </div>


      <!-- 测试 -->
      <div v-if="roleName =='ceshi'">
        <el-row>
          <el-col :span="7">
            <el-input placeholder="请输入成员名称" @keyup.enter="getItemMemList()" clearable @clear="getItemMemList()">
              <template #append>
                <el-button icon="el-icon-search" @click="getItemMemList()"></el-button>
              </template>
            </el-input>
          </el-col>
          <el-col :span="14"></el-col>
          <el-col :span="3">
            <el-button style="width:100%;padding:1px" type="primary" icon="el-icon-plus" @click="addDialogVisible=true">
              添加</el-button>
          </el-col>
        </el-row>
        <el-tabs type="border-card" style="margin-top:10px">
          <el-tab-pane>
            <template #label>全部</template>
            <!-- 全部属于该公司的用户 -->
            <el-table :data="itemCharge" stripe border style="width: 100%">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column label="成员名称" prop="name"></el-table-column>
              <el-table-column label="成员岗位" prop="role"></el-table-column>
              <el-table-column label="所属项目">
                <template v-slot="scope">
                  <!-- <span>{{this.getItemNameList(scope.row)}}</span> -->
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="180px">
                <template v-slot="scope">
                  <!-- 如果该成员不属于本项目，才显示添加按钮 -->
                  <el-button size="mini" type="primary" icon="el-icon-circle-plus-outline"
                    @click="addUser(scope.row.name)" :disabled="this.getItemState(scope.row)">添加</el-button>
                  <!-- 如果成员属于本项目，才显示删除按钮 -->
                  <el-button size="mini" type="danger" icon="el-icon-delete" @click="removeUser(scope.row.name)"
                    :disabled="!this.getItemState(scope.row)">移出</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>


        </el-tabs>

      </div>


    </el-card>




    <el-dialog v-model="addDialogVisible" title="添加项目" width="50%" @closed="closedAddForm()">
      <el-form :model="addForm" :rules="rules" ref="addForm" label-width="100px">
        <el-form-item label="项目名称" prop="itemName">
          <el-input v-model="addForm.itemName"></el-input>
        </el-form-item>
        <el-form-item label="项目负责人" prop="userId">
          <!-- 项目负责人可以从 属于该公司且岗位为项目负责人的人中选择 以下拉框-->
          <el-select v-model="addForm.userId" placeholder="请选择项目负责人" clearable filterable style="width:100%"
            no-match-text="无匹配成员">
            <el-option v-for="item in memberList" :key="item.id" :label="item.phone" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 底部 -->
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="addItem('addForm')">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  import zhCn from "element-plus/lib/locale/lang/zh-cn";
  export default {
    data() {
      const validateName = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入项目名称"));
        } else {
          callback();
        }
      };

      return {
        itemQuery: {
          query: "",
          pagenum: 1,
          pagesize: 5,
        },
        itemTotal: 0,
        curAllMem:[],
        itemCharge:[],
        itemMemList: [],
        curItemId: 0,
        roleName: "",
        rules: {
          itemName: [{
            validator: validateName,
            trigger: "blur"
          }],
        },
        addDialogVisible: false,
      };
    },
    setup() {
      return {
        locale: zhCn
      };
    },
    mounted() {
      this.roleName = window.sessionStorage.getItem('roleName')
      this.itemId = window.sessionStorage.getItem('activeItem')
      if(this.roleName == "项目负责人" || this.roleName == "公司管理员") {this.getItemMemList();}
    },
    methods: {
      //获取项目下的所有成员
      async getItemMemList() {
        this.itemQuery.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get("/companyItem/getItemMemberList", {
          params: this.itemQuery
        });
        if (res.code != 200) return this.$message.error(res.msg);
        this.itemMemList = res.data.list.splice(1);
        this.itemTotal = res.data.total
      },
      ItemSizeChange(newSize) {
        this.itemQuery.pagesize = newSize;
        this.getItemMemList();
      },
      ItemCurChange(newPage) {
        this.itemQuery.pagenum = newPage;
        this.getItemMemList();
      },
      getItemState(itemMem) {
        var List = itemMem['itemList']
        for (var i of List) {
          if (this.curItemId === i['id']) {
            return true;
          }
        }
        return false;
      },
      async removeUser(user) {
        const confirmRes = await this.$confirm("确认移出该用户？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).catch(err => console.log(err));
        if (confirmRes !== "confirm") {
          return this.$message.info("取消移出");
        }
        let formData = new FormData();
        formData.append('addUserId', user.id);
        formData.append('itemId', this.itemId)
        const {
          data: res
        } = await this.$http.post("/companyItem/removeItemMember", formData);
        if (res.code != 200) return this.$message.error("删除失败，您暂无此权限")
        this.$message.info(res.msg);
        this.getItemMemList();
      },
      async addUser(user) {
        const confirmRes = await this.$confirm("确认添加该用户？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).catch(err => console.log(err));
        if (confirmRes !== "confirm") {
          return this.$message.info("取消添加");
        }
        console.log("add" + user)
      }
    }
  };
</script>

<style lang="less" scoped>
</style>
